<template>
  <div class="rentImg">
<!--    这里有一张图片-->
    <img src="../../images/rentBigimg.png" alt="">
<!--    返回按钮s-->
    <div @click="closeMessages" class="msgPopUpShowStyle">
      <img :src="require('../../images/backPre.png')" alt="">
    </div>
<!--    返回按钮e-->
  </div>
</template>

<script>
export default {
  name: "rentThings",
  // data(){
  //   return{
  //   }
  // },
  methods:{
    closeMessages(){
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.rentImg{
  //padding-bottom: 1.6rem;
  >img{
    width: 100%;
  }
}
.msgPopUpShowStyle {
  position: fixed;
  left: 0.1rem;
  top: 82%;
  width: 1.083rem;
  height: 1.083rem;
  text-align: center;
  background: #F6F6F6;
  border-radius: 50%;
  line-height: 70px;
img{
  width: 100%;
}
}
</style>
